.AccountMenu {
  .Icon {
    margin-right: 4px;
  }
  .Icon__tronIcon {
    width: 20px;
  }
  .Icon__tokenIcon {
    width: 22px;
  }
  .ant-menu-item-disabled {
    color: #fff !important;
    cursor: default;
  }
  &__item {
    height: 22px !important;
    line-height: 22px !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  &__guideTrigger {
    height: 22px !important;
    line-height: 22px !important;
    margin-bottom: 20px !important;
    > button {
      padding: 0;
      color: rgba(255, 255, 255, 0.65);
      height: auto;
      &:hover {
        color: #fff;
      }
    }
  }
  &__balance {
    &:first-child {
      margin-top: 12px !important;
    }
    &:nth-child(3) {
      margin-bottom: 12px !important;
    }
    height: 30px !important;
    line-height: 30px !important;
    margin-left: -4px !important;
  }
}