.CurrencySelect {
  background-color: rgba($color: #07245A, $alpha: 0.67);
  height: 64px;
  border-radius: 10px;
  -webkit-box-shadow: inset 6px 14px 16px -2px rgba(7,36,90,1);
  -moz-box-shadow: inset 6px 14px 16px -2px rgba(7,36,90,1);
  box-shadow: inset 6px 14px 16px -2px rgba(7,36,90,1);
  > div.ant-select-selector {
    height: 64px !important;
  }
  &.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 30px;
  }
  .ant-select-arrow {
    font-size: 32px;
    width: 32px;
    height: 32px;
    top: 38%;
    color: rgba($color: #fff, $alpha: 0.4);
    .anticon:not(.anticon-down) {
      pointer-events: none !important;
    }
  }
  &:hover {
    .ant-select-arrow {
      color: rgba($color: #fff, $alpha: 0.85);
    }
  }
}

.CurrencySelect__options {
  background-color: #001529;
  .ant-select-item-option-active {
    background-color: rgba($color: #fff, $alpha: 0.4);
  }
  .ant-select-item-option-selected {
    background-color: rgba($color: #fff, $alpha: 0.2);
  }
}

.CurrencySelect__option {
  display: flex;
  height: 64px;
  &__leftSide {
    display: flex;
    align-items: center;
  }
  &__rightSide {
    display: flex;
    flex-direction: column;
    line-height: 22px;
    padding-left: 10px;
    justify-content: center;
  }
  &__data {
    margin-top: 1px;
  }
  &__balance {
    line-height: 20px;
    font-size: 13px;
    color: #D8D8D8;
  }
  &__value {
    line-height: 20px;
    font-size: 13px;
    color: #D8D8D8;
    margin-left: 8px;
    padding-left: 8px;
    position: relative;
    &::before {
      content: "";
      border-left: 1px solid #D8D8D8;
      position: absolute;
      left: 0;
      top: calc(50% - 5px);
      height: 11px;
    }
  }
  .Icon {
    &__tokenIcon {
      width: 46px;
    }
    &__cnrText,
    &__cnsText {
      height: 18px;
    }
  }
}