.Summary {
  background: rgba(0,0,0,0.25);
  display: flex;
  justify-content: center;
  &__inner {
    padding: 20px 15px;
    display: flex;
    justify-content: space-around;
  }
}

.Summary__item {
  display: flex;
  flex-direction: column;
  &__label {
    color: #ffffff;
    font-size: 15px;
    line-height: 18px;
    font-weight: 300;
  }
  &__value {
    color: #ffffff;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    .screen-md & {
      font-size: 26px;
      line-height: 28px;
    }
  }
  &__icon {      
    display: none;
    .Icon__tokenIcon {
      width: 60px;
    }
    .screen-md & {
      display: inline-block;
    }
  }
}

.Summary__left {
  display: flex;
  align-items: center;
  justify-content: center;
    flex-direction: column-reverse;
  .Summary__item {
    text-align: center;
    &__icon {
      padding: 6px 0 6px 0;
      .screen-md & {
        padding: 0 0 0 14px;
      }
    }
    .screen-md & {
      text-align: right;
    }
  }
  .screen-md & {
    justify-content: flex-end;
    flex-direction: row;
  }
}

.Summary__right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .Summary__item {
    text-align: center;
    &__icon {
      padding: 6px 0 6px 0;
      .screen-md & {
        padding: 0 14px 0 0;
      }
    }
    .screen-md & {
      text-align: left;
    }
  }
  .screen-md & {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.Summary__icon {
  display: flex;
  justify-content: center;
  > img {
    opacity: 0.5;
    transform: rotate(90deg);
    margin: 6px;
    width: 14px;
    .screen-md & {
      transform: rotate(0deg);
      margin: 0;
      width: auto;
    }
  }
  &.blink {
    > img {
      animation: blink 1s ease-in infinite;
    }
  }
}

@keyframes blink{
  0%{opacity: 0.5;}
  50%{opacity: .75;}
  100%{opacity: 1;}
}