.Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 15px 6px 15px;
  .Icon__swapLogo {
    max-width: 150px;
  }
}

.Header__dropdown {
  width: 180px;
  justify-content: flex-end;
  padding-right: 0;
  &__button {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    &:active,
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.65);
    } 
    .ant-badge-status-dot {
      width: 9px;
      height: 9px;
    }
    .ant-badge-status-text {
      margin-left: 8px;
    }
  }
}